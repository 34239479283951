import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import ErrorPage from "./pages/ErrorPage";
import SignUp from "./pages/SignUp";
// import Layout from "./components/layout/Layout";
import Landing from "./pages/Landing";
import LayoutIndex from "./components/layout/LayoutIndex";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Test from "./pages/Test";

const router = createBrowserRouter([
	// {
	// 	path: "/",
	// 	element: <LayoutIndex />,
	// 	errorElement: <ErrorPage />,
	// 	children: [{ index: true, element: <Landing /> }],
	// },
	{
		path: "/",
		element: <LayoutIndex />,
		errorElement: <ErrorPage />,
		children: [
			{ index: true, element: <Landing /> },
			{ path: "sign-up", element: <SignUp /> },
			{ path: "privacy", element: <Privacy /> },
			{ path: "terms", element: <Terms /> },
			{ path: "test", element: <Test /> },
		],
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
