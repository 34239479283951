import { css } from "styled-components";
import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const StyledRoot = styled("div")(
	({ theme }) => css`
		#main {
		}
	`
);

const LayoutIndex = () => {
	return (
		<StyledRoot>
			<Header landing />
			<div id='main'>
				<Outlet />
			</div>
			<Footer landing />
			<div id='overlay'></div>
		</StyledRoot>
	);
};

export default LayoutIndex;
