import "../App.css";
import { css } from "styled-components";
import { styled } from "@mui/material/styles";
import Carousel from "react-material-ui-carousel";
import { ImageListItem, ImageListItemBar, Link } from "@mui/material";

import Contact from "../components/Contact";
import banner1 from "../assets/images/banner1.jpg";
import imgBg1 from "../assets/images/imgBg1.svg";
import imgBg2 from "../assets/images/imgBg2.svg";
import notarycafe from "../assets/images/notarycafe.gif";
import newyork from "../assets/images/newyork.jpg";

import services01 from "../assets/images/services-01.jpg";
import services02 from "../assets/images/services-02.jpg";
import services03 from "../assets/images/services-03.jpg";
import services04 from "../assets/images/services-04.jpg";
import services05 from "../assets/images/services-05.jpg";
import services06 from "../assets/images/services-06.jpg";

import imgBgFeat from "../assets/images/imgBgFeat.svg";
import image1 from "../assets/images/easy-order-mgt/image1.png";
import image2 from "../assets/images/easy-order-mgt/image2.png";
import image3 from "../assets/images/easy-order-mgt/image3.png";

import ft_image3 from "../assets/images/features/image3.png";
import ft_image4 from "../assets/images/features/image4.png";
import ft_image5 from "../assets/images/features/image5.png";
import ft_image6 from "../assets/images/features/image6.png";

import imgBgFeat1 from "../assets/images/imgBgFeat1.svg";
import imgBgFeat2 from "../assets/images/imgBgFeat2.svg";
import arrows08 from "../assets/images/arrows-08.png";

const StyledRoot = styled("div")(
	({ theme }) => css`
		.gradient-wrap {
			position: relative;

			img {
				border-radius: 20px;
				opacity: 0.8;
			}
		}

		.gradient-wrap:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 50%;
			background: linear-gradient(to right, rgb(231 250 239) 0%, transparent 100%);
			height: 100%;
		}

		@media screen and (max-width: 1300px) {
			.gradient-wrap {
				position: relative;

				img {
					opacity: 1;
				}
			}
			.gradient-wrap:after {
				height: 95%;
				background: none;
			}
		}

		@media screen and (min-width: 992px) and (max-width: 1299px) {
			#aboutus {
				.col2-About {
					justify-content: center;
					display: flex;
				}
			}

			.easy-order-mgt {
				.col1-Feat {
					width: 36%;
				}

				.col2-Feat {
					width: 52%;
				}
			}

			.features {
				.col1-Feat2 {
					width: 52%;
				}

				.col2-Feat2 {
					width: 36%;
				}
			}

			#pricing {
				.col1-Pricing {
					width: 40%;
				}
				.col2-Pricing {
					width: 45%;
				}
			}

			#contact {
				.col1-Contact {
					width: 40%;
				}
				.col2-Contact {
					width: 50%;
				}
			}
		}

		.carousel {
			.MuiImageListItem-root {
				display: block;
				position: relative;
				border-radius: 15px;
				overflow-y: hidden;

				.MuiImageListItemBar-title {
					font-size: 1.25rem;
					line-height: 40px;
				}

				.MuiImageListItemBar-positionTop {
					background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%);
				}
			}
		}

		.features {
			position: relative;
			padding: 40px 40px;
			min-height: 480px;

			.row {
				margin: auto;

				> div {
					margin: auto;
				}

				.iconList {
					padding-top: 45px;
				}

				.carousel {
					.MuiImageListItem-root {
						border-radius: 0px;

						img {
							max-width: 75%;
							margin: auto;
							display: block;
						}
					}
				}
			}
		}
	`
);

function Landing() {
	const handleClickScroll = (section) => {
		const element = document.getElementById(section);
		if (element) {
			element.scrollIntoView({ block: "start", behavior: "smooth" });
		}
	};

	const easyOrderManagement = [
		{ name: "Manage Request List", description: "", img: image1 },
		{ name: "Signing Request Form", description: "", img: image2 },
		{ name: "Invoice for Signing Engagement", description: "", img: image3 },
	];

	const responsiveImages = [{ img: ft_image3 }, { img: ft_image4 }, { img: ft_image5 }, { img: ft_image6 }];

	const commonStyleFooter = {
		backgroundColor: "#212827",
		color: "#fff",
		position: "relative",
		padding: "25px 0",
	};

	return (
		<StyledRoot>
			{/* <!--STAR BANNER CARROUSEL--> */}
			<div id='home' className='bg-green'>
				<div className='container'>
					<div className='row paddingBanner z-index-1'>
						<div className='col1-Banner'>
							<h1 className='titleBanner'>Close MORE signings in less time</h1>
							<p className='description'>The all-in-one tool for your signing service business.</p>
							<Link className='btn-green-Banner' onClick={() => handleClickScroll("contact")}>
								Get Started
							</Link>
						</div>
						<div className='col2-Banner gradient-wrap'>
							<img src={banner1} alt='' className='w-100' />
						</div>
					</div>
				</div>

				<img src={imgBg1} alt='' className='imgBg1' />
				<img src={imgBg2} alt='' className='imgBg2' />
			</div>
			{/* <!--END BANNER CARROUSEL--> */}

			{/* <!--STAR PARRAFO ABOUT US--> */}
			<div id='aboutus' className='container paddingTop paddingBottom'>
				<div className='row'>
					<div className='col1-About'>
						<h2 className='titleH2'>About us</h2>
						<p className='parrafo'>
							NotarySoftware.com was started in Vancouver, Washington in 2005 by a mortgage industry professional and a
							software developer putting their heads together to meet a specific need that was identified in the loan
							signing industry by signing services.
							<br />
							<br />
							Professional notaries and signing services were consulted to create a total software solution that
							benefited both the signing services and the notaries. Through their feedback and suggestions, the
							NotarySoftware.com loan signing platform was born.
						</p>

						<div className='numberPorcentaje'>
							<span className='numberInPor c-green'>100%</span>
							<p className='txtSpan'> Customer focused</p> <br />
							<span className='numberInPor c-red'>2005</span>
							<p className='txtSpan'>Year Founded</p>
						</div>

						<p className='parrafo titleBold'>What is Notary Café?</p>
						<p className='parrafo marginTopBottom'>
							Notary Café is powered by Notary Software. Notary Café is the number one resource for connecting
							professional notaries with the people who need them. Whether you are a notary or you need a notary, you
							can benefit from our community. Our system is set up to be easy to use and understand, saving you time and
							making the process of connecting notaries and those who need them simple and efficient.
						</p>
						<div className='linksBox'>
							<p className='parrafo'>Are you a signing agent?</p>
							<a href='https://www.notarycafe.com' target='_blank' rel='noreferrer' className='linkGreen'>
								Join Notary Café Today!
							</a>
							<img src={notarycafe} alt='' className='imgNotary' />
						</div>
					</div>
					<div className='col2-About'>
						<div className='col2-About-wrapper'>
							<div className='bloqGreen'>
								<p className='est'>EST.</p>
								<p className='ano'>2005</p>
							</div>
							<img src={newyork} alt='' className='imgAbout' />
						</div>
					</div>
				</div>
			</div>
			{/* <!--END PARRAFO ABOUT US--> */}

			<div id='tour' className='paddingTop paddingBottom' style={{ position: "relative" }}>
				<div className='container z-index-1'>
					<div className='row'>
						<h2 className='titleH2 txtcenter'>Features</h2>
						<p className='parrafo boxCenter txtcenter'>
							Our comprehensive order management platform simplifies the process from order creation to completion, so
							you can "Sign More in Less Time."
						</p>
					</div>
					<div className='row boxPadre'>
						<div className='boxFeat'>
							<img src={services01} alt='' className='imgServices' />
							<h3 className='titleBold txtcenter marginImp'>Drag and Drop</h3>
							<p className='parrafo txtcenter'>
								Drag and drop file uploads into our system or connect to files in an external resource such as Dropbox.
							</p>
						</div>
						<div className='boxFeat'>
							<img src={services02} alt='' className='imgServices' />
							<h3 className='titleBold txtcenter marginImp'>Title Software Integration</h3>
							<p className='parrafo txtcenter'>
								Title Loop Software integration for easy order creation through title software applications such as
								Resware and Ramquest.
							</p>
						</div>
						<div className='boxFeat'>
							<img src={services03} alt='' className='imgServices' />
							<h3 className='titleBold txtcenter marginImp'>Agent Availability Requests</h3>
							<p className='parrafo txtcenter'>
								Request agent availability with your proposed fees with a few quick clicks.
							</p>
						</div>
						<div className='boxFeat'>
							<img src={services04} alt='' className='imgServices' />
							<h3 className='titleBold txtcenter marginImp'>Notary Database</h3>
							<p className='parrafo txtcenter'>
								Notary Café is Notary Software’s agent database. Easily find the most sought after notaries in the
								industry.
							</p>
						</div>
						<div className='boxFeat'>
							<img src={services05} alt='' className='imgServices' />
							<h3 className='titleBold txtcenter marginImp'>Customizable Email</h3>
							<p className='parrafo txtcenter'>Fully customizable email notifications to your clients and notaries.</p>
						</div>
						<div className='boxFeat'>
							<img src={services06} alt='' className='imgServices' />
							<h3 className='titleBold txtcenter marginImp'>Dark Mode</h3>
							<p className='parrafo txtcenter'>Prefer dark mode to reduce eye strain? We've got you covered.</p>
						</div>
					</div>
				</div>
				<img src={imgBgFeat} alt='' className='imgBg1' />
			</div>

			<div className='paddingTop paddingBottom easy-order-mgt' style={{ position: "relative" }}>
				<div className='container z-index-1'>
					<div className='row'>
						<div className='col1-Feat'>
							<h2 className='titleH2'>Easy Order Management</h2>
							<p className='parrafo paddingTop'>Manage all aspects of a request from a single location.</p>
							<ul className='paddingTop iconList'>
								<li>Easily find and assign your favorite notary</li>
								<li>One click email confirmation</li>
								<li>Create and send your invoice in a snap</li>
							</ul>
						</div>
						<div className='col2-Feat'>
							<Carousel className='carousel' animation='fade' autoPlay={true} duration='1000'>
								{easyOrderManagement.map((item, i) => (
									<ImageListItem key={item.img}>
										<ImageListItemBar position='top' title={item.name} subtitle={item.description} />
										<img
											srcSet={`${item.img}?fit=crop&auto=format&dpr=2 2x`}
											src={`${item.img}?fit=crop&auto=format`}
											alt={item.title}
											loading='lazy'
										/>
									</ImageListItem>
								))}
							</Carousel>
						</div>
					</div>
				</div>
				<img src={imgBgFeat1} alt='' className='imgBg2' />
			</div>

			<div className='bg-gray features' style={{}}>
				<div className='container z-index-1'>
					<div className='row'>
						<div className='col1-Feat2' style={{ alignSelf: "center" }}>
							<Carousel className='carousel' animation='fade' autoPlay={true} duration='1000'>
								{responsiveImages.map((item, i) => (
									<ImageListItem key={item.img}>
										<img
											srcSet={`${item.img}?fit=crop&auto=format&dpr=2 2x`}
											src={`${item.img}?fit=crop&auto=format`}
											alt={item.title}
											loading='lazy'
										/>
									</ImageListItem>
								))}
							</Carousel>
						</div>
						<div className='col2-Feat2'>
							<h2 className='titleH2'>Feature Rich Responsive Design</h2>
							<p className='parrafo'></p>
							<ul className='iconList'>
								<li>Mobile Friendly</li>
								<li>Prefer dark mode? We've got you covered</li>
								<li>Rich text editor for your emails</li>
								<li>Agent availability requests that you control</li>
							</ul>
						</div>
					</div>
				</div>
				<img src={imgBgFeat2} alt='' className='imgBg1' />
			</div>

			<div className='bg-green-dark paddingBloq'>
				<div className='boxCenter txtcenter'>
					<h2 className='titleH2 c-white'>Get Started with Notary Software Today</h2>
					<p className='parrafo c-white'>Helping clients enjoy a simpler, faster, and more professional way to</p>
					<p className='parrafo c-white'>“Sign More in Less time” since 2005.</p>
					<div className='linksBox'>
						<Link className='bg-btn-orange-light btn-sectionGreen link' onClick={() => handleClickScroll("contact")}>
							Get Started
						</Link>
						<img src={arrows08} alt='' className='imgArrows' />
						<Link className='nav-link bg-btn-white btn-sectionGreen link' onClick={() => handleClickScroll("contact")}>
							Request a demo
						</Link>
					</div>
				</div>
			</div>

			<div id='pricing' className='container paddingBanner'>
				<div className='row'>
					<h2 className='titleH2 txtcenter boxCenter2 w-100'>Our Pricing Has Nothing to Hide</h2>
					<p className='parrafo   txtcenter'>No annual commitment, cancel any time.</p>
				</div>
				<div className='row'>
					<div className='col1-Pricing'>
						<h2 className='titleH2 paddingTop'>Managing your signing service has never been this easy!</h2>
						<ul className=' iconList'>
							<li>Drag And Drop Documents</li>
							<li>Invoice Clients</li>
							<li>Agent Availability Requests</li>
							<li>Customizable Email Notifications</li>
							<li>A Notary Database With Integrity</li>
						</ul>
					</div>
					<div className='col2-Pricing paddingTop'>
						<div className='boxPrincing'>
							<span className='plan'>Plan</span>
							<h3 className='signingService'>Signing Service</h3>
							<hr />
							<span className='txtAs'>As low as</span>
							<div className='bloqMargin'>
								<span className='numberSpan'>$2.00</span>
								<span className='parrafo'>{"  "}per request</span>
							</div>
							<Link className='btn-green-Pricing link' onClick={() => handleClickScroll("contact")}>
								Get Started
							</Link>
						</div>
					</div>
				</div>
			</div>

			<Contact {...commonStyleFooter} />

			<div style={{ height: "10px", backgroundColor: "#212827" }}>
				<div style={{ maxWidth: "1296px", margin: "auto" }}>
					<hr style={{ border: "1px solid #52525b" }} />
				</div>
			</div>
		</StyledRoot>
	);
}

export default Landing;
