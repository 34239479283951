import { useEffect } from "react";
import { css } from "styled-components";
import { styled } from "@mui/material/styles";
import { Divider } from "@mui/material";

const StyledRoot = styled("div")(({ theme }) => css``);

const Privacy = () => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		return () => {};
	}, []);

	return (
		<StyledRoot id='privacy' className='container paddingHeader'>
			<br />
			<h3 className='titleH3'>Privacy Statement</h3>
			<Divider />
			<br />
			<p className='parrafo'>
				Notary Software is committed to protecting your privacy and developing technology that gives you the most
				powerful and safe online experience. This Statement of Privacy applies to the Notary Software Web site and
				governs data collection and usage. By using the Notary Software website, you consent to the data practices
				described in this statement.
			</p>
			<br />
			<h4 className='titleH4'>Collection of your Personal Information</h4>
			<p className='parrafo'>
				Notary Software collects personally identifiable information, such as your e-mail address, name, home or work
				address or telephone number. Notary Software also collects anonymous demographic information, which is not
				unique to you, such as your ZIP code, age, gender, preferences, interests and favorites.
			</p>
			<br />
			<p className='parrafo'>
				There is also information about your computer hardware and software that is automatically collected by Notary
				Software. This information can include: your IP address, browser type, domain names, access times and referring
				Web site addresses. This information is used by Notary Software for the operation of the service, to maintain
				quality of the service, and to provide general statistics regarding use of the Notary Software Web site.
			</p>
			<br />
			<p className='parrafo'>
				Please keep in mind that if you directly disclose personally identifiable information or personally sensitive
				data through Notary Software public message boards, this information may be collected and used by others. Note:
				Notary Software does not read any of your private online communications.
			</p>
			<br />
			<p className='parrafo'>
				Notary Software encourages you to review the privacy statements of Web sites you choose to link to from Notary
				Software so that you can understand how those Web sites collect, use and share your information. Notary Software
				is not responsible for the privacy statements or other content on Web sites outside of the Notary Software and
				Notary Software family of Web sites.
			</p>
			<br />
			<h4 className='titleH4'>Use of your Personal Information</h4>
			<p className='parrafo'>
				Notary Software collects and uses your personal information to operate the Notary Software Web site and deliver
				the services you have requested. Notary Software also uses your personally identifiable information to inform
				you of other products or services available from Notary Software and its affiliates. Notary Software may also
				contact you via surveys to conduct research about your opinion of current services or of potential new services
				that may be offered.
			</p>
			<br />
			<p className='parrafo'>
				Notary Software does not sell, rent or lease its customer lists to third parties. Notary Software may, from time
				to time, contact you on behalf of external business partners about a particular offering that may be of interest
				to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone
				number) is not transferred to the third party. In addition, Notary Software may share data with trusted partners
				to help us perform statistical analysis, send you email or postal mail, provide customer support, or arrange for
				deliveries. All such third parties are prohibited from using your personal information except to provide these
				services to Notary Software, and they are required to maintain the confidentiality of your information.
			</p>
			<br />
			<p className='parrafo'>
				Notary Software does not use or disclose sensitive personal information, such as race, religion, or political
				affiliations, without your explicit consent.
			</p>
			<br />
			<p className='parrafo'>
				Notary Software keeps track of the Web sites and pages our customers visit within Notary Software, in order to
				determine what Notary Software services are the most popular. This data is used to deliver customized content
				and advertising within Notary Software to customers whose behavior indicates that they are interested in a
				particular subject area.
			</p>
			<br />
			<p className='parrafo'>
				Notary Software Web sites will disclose your personal information, without notice, only if required to do so by
				law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply
				with legal process served on Notary Software or the site; (b) protect and defend the rights or property of
				Notary Software; and, (c) act under exigent circumstances to protect the personal safety of users of Notary
				Software, or the public.
			</p>
			<br />
			<h4 className='titleH4'>Use of Cookies</h4>
			<p className='parrafo'>
				The Notary Software Web site use "cookies" to help you personalize your online experience. A cookie is a text
				file that is placed on your hard disk by a Web page server. Cookies cannot be used to run programs or deliver
				viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the
				domain that issued the cookie to you.
			</p>
			<br />
			<p className='parrafo'>
				One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a
				cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize
				Notary Software pages, or register with Notary Software site or services, a cookie helps Notary Software to
				recall your specific information on subsequent visits. This simplifies the process of recording your personal
				information, such as billing addresses, shipping addresses, and so on. When you return to the same Notary
				Software Web site, the information you previously provided can be retrieved, so you can easily use the Notary
				Software features that you customized.
			</p>
			<br />
			<p className='parrafo'>
				You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can
				usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may
				not be able to fully experience the interactive features of the Notary Software services or Web sites you visit.
			</p>
			<br />
			<h4 className='titleH4'>Security of your Personal Information</h4>
			<p className='parrafo'>
				Notary Software secures your personal information from unauthorized access, use or disclosure. Notary Software
				secures the personally identifiable information you provide on computer servers in a controlled, secure
				environment, protected from unauthorized access, use or disclosure. When personal information (such as a credit
				card number) is transmitted to other Web sites, it is protected through the use of encryption, such as the
				Secure Socket Layer (SSL) protocol.
			</p>
			<br />
			<h4 className='titleH4'>Changes to this Statement</h4>
			<p className='parrafo'>
				Notary Software will occasionally update this Statement of Privacy to reflect company and customer feedback.
				Notary Software encourages you to periodically review this Statement to be informed of how Notary Software is
				protecting your information.
			</p>
			<br />
			<h4 className='titleH4'>Contact Information</h4>
			<p className='parrafo'>
				Notary Software welcomes your comments regarding this Statement of Privacy. If you believe that Notary Software
				has not adhered to this Statement, please contact Notary Software at{" "}
				<a href='mailto:support@notarysoftware.com'>support@notarysoftware.com</a>... We will use commercially
				reasonable efforts to promptly determine and remedy the problem.
			</p>
		</StyledRoot>
	);
};

export default Privacy;
