import { useEffect, useRef, useState } from "react";
import { css } from "styled-components";
import { styled } from "@mui/material/styles";
import imgBgFooter from "../assets/images/imgBgFooter.png";
import logoFooter from "../assets/images/logoFooter.png";
import iconOutlineMail from "../assets/images/icon-outline-mail.svg";
import iconOutlineLocationMarker from "../assets/images/icon-outline-location-marker.svg";
import { Alert, Card, FormControl, MenuItem, Select, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
import ReCAPTCHA from "react-google-recaptcha";

const StyledRoot = styled("div")(
	({ theme }) => css`
		.contact-form {
			max-width: 500px;

			input[type="submit"] {
				width: 100%;
				margin: 0px;
				cursor: pointer;
			}

			input {
				height: 35px;
				padding: 5px 12px 5px 12px;
				border-radius: 4px;
				border: solid 1px #d1d0d6;
			}

			.MuiSelect-root:hover {
				border-color: lightgrey;

				.MuiOutlinedInput-notchedOutline {
					border-color: lightgray !important;
				}
			}

			.MuiSelect-select {
				height: 30px;
				line-height: 30px;
				font-family: "Inter";
				font-weight: 400;
				font-size: 0.8rem;
			}

			textarea {
				width: auto;
				height: 70px;
				padding: 5px 12px 5px 12px;
				border-radius: 4px;
				border: solid 1px #d1d0d6;
			}

			form > .form-group {
				flex: 1;
				margin-bottom: 10px;
			}

			form > .form-group.name-and-phone {
				display: flex;
				justify-content: space-between;
				gap: 15px;
			}

			.enviar input {
				border: none;
			}

			.captcha {
				.error {
					color: red;
					padding: 5px 5px;
					font-size: 0.8rem;
				}
			}
		}

		@media screen and (max-width: 500px) {
			.contact-form {
				form > .form-group.name-and-phone {
					display: block;
				}

				form > .form-group.name-and-phone > div {
					margin-bottom: 10px;
				}
			}
		}
	`
);

const Contact = (props) => {
	const [socketlabsBody, setSocketlabsBody] = useState(() => {
		return {
			customerEmail: "",
			subject: "",
			message: "",
		};
	});

	const [form, setForm] = useState();
	const [showEmailSnackbar, setShowEmailSnackbar] = useState(false);

	const captchaRef = useRef();
	const [captcha, setCaptcha] = useState(() => {
		return { verifiedError: "", verified: false };
	});

	useEffect(() => {
		setSocketlabsBody({
			customerEmail: form?.email,
			subject: `${form?.topic}`,
			message:
				"<ul>" +
				`<li><b>Topic:</b> ${form?.topic}</li>` +
				`<li><b>Customer Name:</b> ${form?.name}</li>` +
				`<li><b>Customer Email:</b> ${form?.email}</li>` +
				`<li><b>Customer Phone Number:</b> ${form?.phoneNumber}</li>` +
				`<li><b>Message:</b> ${form?.message}</li>` +
				"</ul>",
		});

		return () => {};
	}, [form]);

	const verifyCaptcha = async () => {
		if (process.env.NODE_ENV !== "development") {
			const captchaValue = captchaRef.current.getValue();
			if (!captchaValue) {
				setCaptcha((v) => ({ ...v, verifiedError: "ReCAPTCHA is requiered." }));
				return false;
			} else {
				const res = await fetch(`${process.env.REACT_APP_SERVICE_URL}/anonymous/verifyCaptcha`, {
					method: "POST",
					body: JSON.stringify(captchaValue),
					headers: { "content-type": "application/json" },
				});
				const data = await res.json();
				if (data.success) return true;
				else return false;
			}
		} else return true;
	};

	const sendContactEmail = async (event) => {
		event.preventDefault();

		if (!(await verifyCaptcha())) {
			setShowEmailSnackbar({ open: true, failed: true, msg: "Sorry. Unable to verify re-captcha." });
			setCaptcha({ verifiedError: "", verified: false });
			captchaRef.current?.reset();
			return;
		}

		try {
			const response = await fetch(`${process.env.REACT_APP_SERVICE_URL}/anonymous/sendSimpleEmail`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(socketlabsBody),
			});

			// network error in the 4xx–5xx range
			if (!response.ok) {
				setShowEmailSnackbar({ open: true, failed: true, msg: "Sorry. We are unable to send the email." });
			} else {
				console.log("Success!");
				setShowEmailSnackbar({ open: true, msg: "Your email was sent successfully!" });
				setForm();
				setCaptcha({ verifiedError: "", verified: false });
			}
			captchaRef.current?.reset();
		} catch (error) {
			console.error("Error:", error);
			setShowEmailSnackbar({ open: true, failed: true, msg: "Sorry. We are unable to send the email." });
			captchaRef.current?.reset();
		}
	};

	return (
		<StyledRoot
			id='contact'
			className='paddingHeader'
			style={{
				backgroundColor: props.backgroundColor,
				color: props.color,
				position: props.position,
			}}>
			<img src={imgBgFooter} alt='' className='imgBg1' />
			<div className='container'>
				<div className='row'>
					<div className='col1-Contact paddingTop'>
						<h2 className='titleH2 c-white'>Close more signings with less time and get paid faster.</h2>
						<div className='w-100 paddingTop' style={{ display: "flex" }}>
							<img src={iconOutlineMail} alt='' />
							<p>support@notarysoftware.com</p>
						</div>
						<div className='w-100 paddingBottom' style={{ display: "flex" }}>
							<img src={iconOutlineLocationMarker} alt='' />
							<p>Vancover, Washington USA</p>
						</div>
						<p className='titleBold c-white'>Looking for Notary Agent?</p>
						<p>Visit Notary Café to find one today!</p>
						<a href='https://notarycafe.com' target='_blank' rel='noreferrer'>
							<img src={logoFooter} alt='' className='imgLogoFooter' />
						</a>
					</div>
					<div className='col2-Contact paddingTop contact-form'>
						<Card sx={{ p: 2 }} className=''>
							<h3 className='titleContact'>Contact Us 👋</h3>
							<p className='parrafo paddingBottom2'>
								If you've got questions, we've got answers. Fill in your information here and we'll get in touch.
							</p>
							<Box component='form' onSubmit={(e) => sendContactEmail(e)}>
								<div className='form-group name-and-phone'>
									<div className='form-group' style={{ flex: 1 }}>
										<label className='c-black'>Your name*</label>
										<input
											className='form-control'
											type='text'
											name='nombre'
											placeholder='Enter your name'
											required
											value={form?.name || ""}
											onChange={(e) => setForm({ ...form, name: e.target.value })}
										/>
									</div>
									<div className='form-group' style={{ flex: 1 }}>
										<label className='c-black'>Your Phone Number*</label>
										<input
											className='form-control'
											type='text'
											name='phone'
											required
											placeholder='Phone Number'
											value={form?.phoneNumber || ""}
											onChange={(e) => setForm({ ...form, phoneNumber: e.target.value })}
										/>
									</div>
								</div>
								<div className='form-group'>
									<label className='c-black'>Your email*</label>
									<input
										className='form-control'
										type='text'
										name='email'
										placeholder='Enter your email'
										required
										value={form?.email || ""}
										onChange={(e) => setForm({ ...form, email: e.target.value })}
									/>
								</div>
								<div className='form-group'>
									<label className='c-black w-100'>Topic*</label>
									<FormControl size='small'>
										<Select
											required
											value={form?.topic || ""}
											onChange={(e) => setForm({ ...form, topic: e.target.value })}
											displayEmpty
											inputProps={{ "aria-label": "Without label" }}>
											<MenuItem disabled value=''>
												Choose topic
											</MenuItem>
											<MenuItem value={"Notary Software - Support Inquiry"}>Notary Software - Support Inquiry</MenuItem>
											<MenuItem value={"Notary Software - Sales Inquiry"}>Notary Software - Sales Inquiry</MenuItem>
										</Select>
									</FormControl>
								</div>
								<div className='form-group'>
									<label className='c-black w-100'>Message*</label>
									<textarea
										className='form-control mensaje-txt'
										rows='8'
										name='mensaje'
										placeholder='Write your message'
										required
										value={form?.message || ""}
										onChange={(e) => setForm({ ...form, message: e.target.value })}></textarea>
								</div>
								{process.env.NODE_ENV !== "development" && (
									<div className='captcha'>
										<ReCAPTCHA
											ref={captchaRef}
											name='verified'
											sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
											style={{ marginTop: "10px", textAlign: "center" }}
											value={captcha.verified}
											onChange={() =>
												setCaptcha((values) => ({
													...values,
													verified: true,
													verifiedError: "",
												}))
											}
										/>
										<p className='error'>{captcha.verifiedError}</p>
									</div>
								)}
								<div className='enviar'>
									{process.env.NODE_ENV !== "development" ? (
										<input
											type='submit'
											disabled={!captcha.verified}
											className={`bg-btn-green btn-submit ${captcha.verified ? "" : "disabled"}`}
										/>
									) : (
										<input type='submit' className='bg-btn-green btn-submit' />
									)}
								</div>
							</Box>
							{showEmailSnackbar.open && (
								<Snackbar
									open={showEmailSnackbar.open}
									autoHideDuration={6000}
									onClose={() => setShowEmailSnackbar(false)}>
									<Alert
										onClose={() => setShowEmailSnackbar(false)}
										severity={showEmailSnackbar.failed ? "error" : "success"}
										variant='filled'
										sx={{ width: "100%" }}>
										{showEmailSnackbar.msg}
									</Alert>
								</Snackbar>
							)}
						</Card>
					</div>
				</div>
			</div>
		</StyledRoot>
	);
};

export default Contact;
