import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";

import logo from "../../assets/images/logotipo.png";
import menu from "../../assets/images/menu.svg";
import close from "../../assets/images/close.svg";

const Header = (props) => {
	const navigate = useNavigate();

	const [openMenu, setOpenMenu] = useState(false);

	const closeMenu = () => {
		setOpenMenu(false);
	};

	const hamburMobileClick = () => {
		setOpenMenu(true);
	};

	const handleClickScroll = (section) => {
		navigate("/");
		setTimeout(() => {
			const element = document.getElementById(section);
			if (element) {
				element.scrollIntoView({ block: "start", behavior: "smooth" });
			}
		}, 250);
		setOpenMenu(false);
	};

	return (
		<header>
			<div className='fixed-top bg-green'>
				<div className='container paddingHeader'>
					<nav className='row navbar'>
						<div className=' '>
							<img src={logo} alt='logo' className='logotipo' onClick={() => navigate("/")} />
						</div>
						<button type='button' className='z-index-menu menuH'>
							<img src={menu} alt='menu' className='hamburMobile' onClick={hamburMobileClick} />
						</button>

						<div className={openMenu ? "menuMobile leftMargin1" : "menuMobile"} id='navbarNav'>
							<div className='closeMenu'>
								<img width='24' height='24' src={close} alt='close' onClick={closeMenu} />
							</div>
							<div className='logoMenuH'>
								<img src={logo} className='logotipo' alt='logo' onClick={() => navigate("/")} />
							</div>

							<ul className='navbar-nav'>
								{props.landing && (
									<>
										<li className='nav-item'>
											<Link className='nav-link link' onClick={() => handleClickScroll("home")}>
												Home
											</Link>
										</li>
										<li className='nav-item'>
											<Link className='nav-link link' onClick={() => handleClickScroll("aboutus")}>
												About
											</Link>
										</li>
										<li className='nav-item'>
											<Link className='nav-link link' onClick={() => handleClickScroll("tour")}>
												Features
											</Link>
										</li>
										<li className='nav-item'>
											<Link className='nav-link link' onClick={() => handleClickScroll("pricing")}>
												Pricing
											</Link>
										</li>
										<li className='nav-item'>
											<Link className='nav-link link' onClick={() => handleClickScroll("contact")}>
												Contact
											</Link>
										</li>
									</>
								)}
								{!props.landing && (
									<>
										<li className='nav-item'></li>
										<li className='nav-item'></li>
										<li className='nav-item'></li>
										<li className='nav-item'></li>
										<li className='nav-item'></li>
									</>
								)}
								<li className='nav-item'>
									<a
										className='nav-link link bg-btn-orange'
										href='https://notarycafe.com'
										target='_blank'
										rel='noreferrer'>
										Find a Notary
									</a>
								</li>
								<li className='nav-item'>
									<Link className='nav-link link bg-btn-green' onClick={() => handleClickScroll("contact")}>
										Get Started
									</Link>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</div>
		</header>
	);
};

export default Header;
