import React from "react";

const Test = () => {
	return (
		<div style={{ marginTop: "100px" }}>
			<form action='?' method='POST'>
				<div class='g-recaptcha' data-sitekey={process.env.REACT_APP_RECAPTCHA_KEY}></div>
				<br />
				<input type='submit' value='Submit' />
			</form>
		</div>
	);
};

export default Test;
