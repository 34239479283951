import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import logoAfterFooter from "../../assets/images/logoAfterFooter.png";

const Footer = (props) => {
	const navigate = useNavigate();
	const [currentYear, setCurrentYear] = useState();

	useEffect(() => {
		let currentDate = new Date();
		setCurrentYear(currentDate.getFullYear());

		return () => {};
	}, []);

	const handleClickScroll = (section) => {
		const element = document.getElementById(section);
		if (element) {
			element.scrollIntoView({ block: "start", behavior: "smooth" });
		}
	};

	return (
		<footer style={{ ...props.style }}>
			<div className='container'>
				<div className='row linksBox'>
					<div className='col1-footer'>
						{props.landing && (
							<ul className='navbar-nav'>
								<li className='nav-link' onClick={() => handleClickScroll("aboutus")}>
									About
								</li>
								<li className='nav-link' onClick={() => handleClickScroll("tour")}>
									Features
								</li>
								<li className='nav-link' onClick={() => handleClickScroll("pricing")}>
									Pricing
								</li>
								<li className='nav-link' onClick={() => handleClickScroll("contact")}>
									Contact
								</li>
							</ul>
						)}
						{!props.landing && (
							<ul className='navbar-nav'>
								<li className='nav-link' onClick={() => navigate("/privacy")}>
									Privacy
								</li>
								<li className='nav-link' onClick={() => navigate("/terms")}>
									Terms
								</li>
							</ul>
						)}
					</div>
					<div className='col2-footer'>
						<img src={logoAfterFooter} alt='' className='w-100' />
					</div>
					<div className='col3-footer'>
						{props.landing && (
							<p className='txt-pie'>
								© <span id='year'>{currentYear}</span> Notary Software, LLC •{" "}
								<Link className='linkFooter link' onClick={() => navigate("/privacy")}>
									Privacy{" "}
								</Link>
								•{" "}
								<Link className='linkFooter link' onClick={() => navigate("/terms")}>
									Terms{" "}
								</Link>
							</p>
						)}
						{!props.landing && (
							<p className='txt-pie'>
								© <span id='year'>{currentYear}</span> Notary Software, LLC
							</p>
						)}
						<p className='txt-pie'>UX/UI Handcrafted with ♥ in New Braunfels, Texas by Bix Agency</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
