import { css } from "styled-components";
import { styled } from "@mui/material/styles";
import { useRouteError } from "react-router-dom";

const StyledRoot = styled("div")(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		min-height: 100vh;
	`
);

export default function ErrorPage() {
	const error = useRouteError();

	return (
		<StyledRoot id='error-page'>
			<h1>Oops!</h1>
			<p>Sorry, an unexpected error has occurred.</p>
			<p>
				<i>{error.statusText || error.message}</i>
			</p>
		</StyledRoot>
	);
}
